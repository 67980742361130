import styled from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
`;

export const Heading = styled.h3`
  ${font('text', 'xl', '700')}
  color: ${colors.gray[800]};
`;
